import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios'; // Import axios for API calls
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const ViewRecommendationManagement = () => {
    const [data, setData] = useState({ list: [] }); // Initialize state to hold API data
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        // Fetch data from the API when component mounts
        const fetchRecommendations = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/admin/v1/manage-recommendations`); // Replace with your API endpoint
                if (response.data.data) {
                    setData(response.data.data); // Set the data received from the API
                }
            } catch (error) {
                console.error('Error fetching recommendations:', error);
            } finally {
                setLoading(false); // Set loading to false after the API call
            }
        };

        fetchRecommendations();
    }, []);

    if (loading) {
        return <Typography>Loading...</Typography>; // Show loading indicator while data is being fetched
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Recommendations
                </Typography>
                <TableContainer component={Paper} elevation={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Question ID</StyledTableCell>
                                <StyledTableCell>Answer</StyledTableCell>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell>Link</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.list.map((item) => (
                                <React.Fragment key={item._id}>
                                    {item.questions.map((question, index) => (
                                        <StyledTableRow key={index}>
                                            <TableCell>{question.questionId}</TableCell>
                                            <TableCell>{question.answer}</TableCell>
                                            {/* Only show title, description, and link for the first question */}
                                            {index === 0 && (
                                                <>
                                                    <TableCell rowSpan={item.questions.length}>{item.title}</TableCell>
                                                    <TableCell rowSpan={item.questions.length}>{item.description}</TableCell>
                                                    <TableCell rowSpan={item.questions.length}>
                                                        <Link href={`${item.link}`} target="_blank" rel="noopener">
                                                            {item.link}
                                                        </Link>
                                                    </TableCell>
                                                </>
                                            )}
                                        </StyledTableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default ViewRecommendationManagement;
