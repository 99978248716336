import React, {  } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Article, Home, Logout, Person, Recommend, Search, Settings } from '@mui/icons-material'
function DrawerNav() {
    const navigate = useNavigate()
    const handleRoute = (url) => {
        navigate('/' + url)
    }

    const isMobile = useMediaQuery('(max-width:600px)')

    const contestListItems = [
        {
            value: 'Home',
            route: '',
            icon: <Home />
        },
        {
            value: 'Add Article',
            route: 'add-article',
            icon: <Article />
        },
        {
            value: 'Add Recommendation',
            route: 'add-recommendation',
            icon: <Recommend />
        }
    ]

    const mainListItems = [
        {
            value: 'Articles',
            route: 'articles',
            icon: <Article />
        },
        {
            value: 'Recommendations',
            route: 'recommendations',
            icon: <Recommend />
        },
    ]

    const mainListItems3 = [
        {
            value: 'Recommendation',
            route: 'create-recommendation',
            icon: <Settings />
        },
        {
            value: 'View Recommendation',
            route: 'view-recommendation-management',
            icon: <Settings />
        },
        {
            value: 'Users',
            route: 'users',
            icon: <Person />
        },
        {
            value: 'Search Users',
            route: 'search-users',
            icon: <Search />
        }
    ]

    const drawerWidth = 300

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {contestListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {mainListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {mainListItems3.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem key={12} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    localStorage.clear()
                                    handleRoute('login')
                                }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary='Logout' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default DrawerNav
